body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  background-color: #09081a; /* Mudança da cor de fundo aqui */
  color: white;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  margin: auto;
  width: 80%;
  padding: 5%;
}

.navbar, .logo-section, .solucoes {
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 20px;
}

.navbar a {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 20px;
  background-color: transparent;
  color: white;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.navbar a:hover {
  background-color: white;
  color: black;
}

.hero,
.about-us,
.products,
.contact-us,
.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 2em;
}

.hero p, .hero h1, .hero h2, .hero h3, .hero h4, .hero h5, .hero h6,
.about-us p, .about-us h1, .about-us h2, .about-us h3, .about-us h4, .about-us h5, .about-us h6,
.products p, .products h1, .products h2, .products h3, .products h4, .products h5, .products h6,
.contact-us p, .contact-us h1, .contact-us h2, .contact-us h3, .contact-us h4, .contact-us h5, .contact-us h6,
.footer p, .footer h1, .footer h2, .footer h3, .footer h4, .footer h5, .footer h6 {
  align-self: flex-start;
}

@media (max-width: 600px) {
  .navbar, .App, .hero, .about-us, .products, .contact-us, .footer {
    flex-direction: column;
    width: 90%;
    padding: 5%;
    margin-bottom: 1em;
  }
}

.footer {
  background: linear-gradient(to bottom, #000000 0%, #0000ff 100%);
  color: black;
  padding: 20px;
  text-align: center;
  width: calc(100% + 10%); /* Adjust according to your parent's padding */
  margin-left: -5%; /* Adjust according to your parent's padding */
  margin-right: -5%; /* Adjust according to your parent's padding */
  border-radius: 15px; /* Apply rounded corners. Adjust the px value to get the desired roundness */
}

.solucao-item {
  flex: 1;
  margin: 0 20px;
  text-align: justify;
  display: flex; /* new line */
  flex-direction: column; /* new line */
  justify-content: flex-end; /* new line */
}

.solucao-item img {
  max-width: 100%;
  height: auto;
}

/* For tablets and smaller devices */
@media (max-width: 768px) {
  .solucoes {
    flex-direction: column;
    align-items: center;
  }

  .solucao-item {
    margin: 20px 0; /* Changed to vertical margins */
  }
}

/* For mobile devices */
@media (max-width: 480px) {
  .solucoes {
    padding: 10px;
  }

  .solucao-item {
    margin: 10px 0; /* Further reduced margins */
  }
}

.contact-us, .contactForm, .contact-form-container {
  text-align: left;
}

.contact-us {
  align-items: flex-start; /* this will align your elements to the left */
}

.contact-form-container {
  width: 100%; /* this ensures your form will take the full width of its parent */
  margin-left: 0;
}
